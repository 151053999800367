<template>
  <div>
    <div v-if="region && market_area">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <div class="page-header">
          <h4 id="top">Regions: <small>{{ region.name }}</small></h4>
        </div>
      </div>
      <div class="card">
        <div class="rainbow-background"></div>
        <div class="table-responsive">
          <table class="card-table table">
            <tbody class="text-left">
            <tr>
              <th scope="col">ID</th>
              <td>{{ region.id }}</td>
            </tr>
            <tr>
              <th scope="col">Name</th>
              <td>{{ region.name }}</td>
            </tr>
            <tr>
              <th scope="col">Region Code</th>
              <td>{{ region.region_code }}</td>
            </tr>
            <tr>
              <th scope="col">Market Area</th>
              <td>
                <router-link :to="{ name: 'MarketAreaShow', params: {id: region.market_area_id }}">{{ this.market_area.name }}</router-link>
              </td>
            </tr>
            <tr>
              <th scope="col">Is Archived?</th>
              <td>{{ region.is_archived }}</td>
            </tr>
            <tr>
              <th scope="col">Created At</th>
              <td>{{ new Date(region.created_at).toDateString() }}, {{ new Date(region.created_at).toLocaleTimeString() }}</td>
            </tr>
            <tr>
              <th scope="col">Updated At</th>
              <td>{{ new Date(region.updated_at).toDateString() }}, {{ new Date(region.created_at).toLocaleTimeString() }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer">
          <div class="btn-group float-right">
            <router-link :to="{ name: 'RegionIndex' }" tag="button" class="btn btn-secondary">Back</router-link>
            <router-link :to="{ name: 'RegionEdit' }" tag="button" class="btn btn-primary">Edit</router-link>
            <button v-on:click="$refs.regionDeleteModal.show()" type="button" class="btn btn-danger">Delete</button>
          </div>
        </div>
      </div>

      <div v-if="regionUsers" class="card">
        <h1>Users Associated to the Region</h1>
        <table class="card-table table">
          <thead>
          <tr>
            <th>User</th>
            <th>Default?</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="regionUser in regionUsers" :key="regionUser.id">
            <td><router-link :to="{ name: 'UserShow', params: { id: regionUser.user_id} }">{{ regionUser.user_name }}</router-link></td>
            <td>{{ regionUser.default }}</td>
            <td><router-link :to="{ name: 'RegionUserShow', params: { id: regionUser.id } }" tag="button" class="btn btn-secondary">Show</router-link></td>
          </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal Component -->
      <b-modal ref="regionDeleteModal" header-bg-variant="danger" header-text-variant="light" class="regionDeleteModal" title="Confirm Delete" ok-variant="danger" ok-title="Delete" @ok="deleteRegion" centered>
        <p>Deleting <span class="font-weight-bold">{{ region.name }}</span> will delete all of the users associated to it – are you sure you want to delete it?</p>
      </b-modal>
    </div>

    <loading-overlay v-else></loading-overlay>


  </div>
</template>

<script>
import { RegionService } from '@/common/services/region.service';
import { MarketAreaService } from '@/common/services/market_area.service';
import { RegionUserService } from '@/common/services/region_user.service';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'RegionShow',
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      region: null,
      regionUsers: null,
      market_area: null,
    };
  },
  created() {
    this.getRegion();
  },
  methods: {
    getRegion() {
      RegionService.get(this.$route.params.id)
        .then((response) => {
          this.region = response.data;
          this.getMarketArea();
        })
        .then(() => {
          this.getRegionUsers();
        });
    },
    getRegionUsers() {
      RegionUserService.query({ region_id: this.region.id })
        .then((response) => {
          this.regionUsers = response.data;
        });
    },
    getMarketArea() {
      MarketAreaService.get(this.region.market_area_id)
        .then((response) => {
          this.market_area = response.data;
        });
    },
    deleteRegion() {
      RegionService.destroy(this.$route.params.id)
        .then((response) => {
          this.$router.push({ name: 'RegionIndex' });
          this.$notify({
            title: `Deleted ${response.data.title}`,
            text: 'Successfully deleted region.',
            type: 'success',
            width: 350,
          });
        });
    },
  },
  beforeRouteUpdate(to) {
    RegionService.get(to.params.id)
      .then((response) => {
        this.region = response.data;
      });
  },
};

</script>

<style lang="scss" scoped>
  @import "../../styles/views/regions";
</style>
